import { computed, makeObservable } from 'mobx';
import { StudyoEnvironmentService } from './StudyoEnvironmentService';

export interface WebEnvironmentService extends StudyoEnvironmentService {
  readonly googleClientId: string;
  readonly googleDrivePickerKey: string;
}

export class AppWebEnvironmentService implements WebEnvironmentService {
  constructor() {
    makeObservable(this);
  }

  @computed
  get apiV3Url() {
    return window.STUDYO_ENV?.apiV3Url ?? '';
  }

  @computed
  get authClientId() {
    return window.STUDYO_ENV?.authClientId ?? '';
  }

  @computed
  get authServiceUrl() {
    return window.STUDYO_ENV?.authServiceUrl ?? '';
  }

  @computed
  get buildNumber() {
    return window.STUDYO_ENV?.buildNumber ?? '';
  }

  @computed
  get environmentName() {
    return window.STUDYO_ENV?.environmentName ?? '';
  }

  @computed
  get formattedVersionNumber() {
    let version = `${this.version}`;

    if (this.buildNumber !== '') {
      version += `.${this.buildNumber}`;
    }

    if (this.environmentName !== '') {
      version += `.${this.environmentName}`;
    }

    return version;
  }

  @computed
  get insightsBaseUrl() {
    return window.STUDYO_ENV?.insightsBaseUrl ?? '';
  }

  @computed
  get grafanaBaseUrl() {
    return window.STUDYO_ENV?.grafanaBaseUrl ?? '';
  }

  @computed
  get intercomAppId() {
    return window.STUDYO_ENV?.intercomAppId ?? '';
  }

  @computed
  get killSwitchUrl() {
    return window.STUDYO_ENV?.killSwitchUrl ?? '';
  }

  @computed
  get version() {
    return window.STUDYO_ENV?.version ?? '';
  }

  @computed
  get googleClientId() {
    return window.STUDYO_ENV?.googleClientId ?? '';
  }

  @computed
  get googleDrivePickerKey() {
    return window.STUDYO_ENV?.googleDrivePickerKey ?? '';
  }

  @computed
  get firebaseConfig() {
    return window.STUDYO_ENV?.firebaseConfig ?? {};
  }
}
