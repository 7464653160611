import { LocalizationService } from '@shared/resources/services';
import { AccountService, NavigationService, StudyoSettingsStore } from '@studyo/services';
import { action, makeObservable } from 'mobx';
import { AppSectionFilterViewModel } from '../SectionFiltersViewModel';

export class AppPlannerSectionFilterViewModel extends AppSectionFilterViewModel {
  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    onSuccess: () => void,
    onCancel: () => void,
    accountService: AccountService,
    settings: StudyoSettingsStore
  ) {
    super(false, localizationService, navigationService, onSuccess, onCancel, accountService, settings);
    makeObservable(this);
  }

  @action
  reset() {
    this.preferences.plannerSectionFilters = [];
    this._selectedSectionIds = [];
  }

  protected get initialSelectedSectionIds() {
    return this.preferences.plannerSectionFilters;
  }

  @action
  protected updateSettings = () => {
    this.preferences.plannerSectionFilters = this._selectedSectionIds;
  };
}
